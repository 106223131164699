import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PauseIcon from '@material-ui/icons/Pause';
import Avatar from "@material-ui/core/Avatar";
import {TextField} from "@material-ui/core";
import TelegramIcon from "@material-ui/icons/Telegram";
import AddIcon from '@material-ui/icons/Add';
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    input: {
        '& > *': {
            margin: theme.spacing(1),
            width: '235px',
        },
    },
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_energy: {
        background: 'linear-gradient(90deg, rgba(71,174,0,1) 0%, rgba(0,203,16,1) 57%, rgba(0,233,11,1) 100%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(230, 255, 232, 1)',
    },
    btn_blog: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    },
}));

const NoEnergy = ({ open, onClose, energy, countEnergy, onPlusEnergy, hash, chat_id, activated }) => {

    const classes = useStyles();

    const [codeActivated, setCodeActivated] = useState(activated); // КОД АКТИВИРОВАН

    const [errorCode, setErrorCode] = useState(false); // отображение ошибки
    const [errorText, setErrorText] = useState(''); // текст ошибки
    const [checkingCode, setCheckingCode] = useState(false); // выполняется проверка кода

    let code = ''; // введенный код в поле

    const onChangeInput = (e) => {
        code = e.target.value;
        console.log('code = ', code);
    };

    const onAddEnergy = () => {

        // отправляем код на сервер для проверки
        setCodeCheck(hash, chat_id, code);

    };

    function setCodeCheck(hash, chat_id, code) {
        if (hash === undefined || hash === '') return;

        setCheckingCode(true); // запущена проверка кода (ждем ответ)

        $.ajax({
            url: 'https://fps1.ru/bunny_fps/game/php/setCodeEnergy.php',
            type: 'post',
            data: {
                hash: hash,
                chat_id: chat_id,
                code: code
            },
            success: function (response) {

                console.log('response check code = ', response);

                // КОД АКТИВИРОВАН
                if (response === 'code_activated') {

                    onPlusEnergy(); // добавлеяем +10 энергии визуально
                    setCodeActivated(true);

                } else {
                    setCodeActivated(false);
                }

                // ошибка секретного слова
                if (response === 'no_code') {
                    setErrorCode(true);
                    setErrorText('Секретное слово не правильное');
                }
                if (response === 'no_actual') {
                    setErrorCode(true);
                    setErrorText('Секретное слово не актуально');
                }
                if (response === 'error_hash') {
                    setErrorCode(true);
                    setErrorText('Ошибка при проверки ключа');
                }
                if (response === 'stop') {
                    setErrorCode(true);
                    setErrorText('Ошибка. Турнир остановлен.');
                }

                setCheckingCode(false);

            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    }

    const onGoURL = () => {
        //window.location.href = "tg://resolve?domain=BunnyFPS";
        window.location.href = "https://t.me/+Mtxc06vRCIcwMTli";
    };
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    {codeActivated ? 'Энергия пополнена!' : (energy < 1 ? 'Энергия закончилась' : 'Получить энергию')}
                </Box>
                <Box className={classes.containerCol} style={{ marginTop: '0.5rem' }}>

                    <Box className={classes.containerRow} style={{ marginTop: '0.1rem', marginBottom: '0.9rem' }}>
                        <Typography variant={'h6'} style={{ marginLeft: '0.5rem' }}> {codeActivated ? '+' + countEnergy : (energy < 1 ? '0' : '+30' )} </Typography>
                        <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/energy3.png" style={{ width: '34px', height: '34px' }}/>
                    </Box>
                    
                    <Typography align={'center'}> Ждите новый турнир! </Typography>                    

                </Box>
            </DialogTitle>
        </Dialog>
    );
}

NoEnergy.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default NoEnergy;
