import React, {useEffect, useMemo, useState} from 'react';

import {randomInteger, saveMyRecord, getFlashVars} from './Utils';
import Iframe from './Iframe';
import TopList from './TopList';
import TurnirTopList from './TurnirTopList';
import StartTurnir from './StartTurnir';
import StopTurnir from "./StopTurnir";
import NoEnergy from "./NoEnergy";
import ResultTurnir from "./ResultTurnir";
import Preloader from "./Preloader";
import Stavka from "./Stavka";
import ModeGame from "./ModeGame";
import Bank from "./Bank";
import Invite from "./Invite";
import StopBot from "./StopBot";
import GoTelegram from "./GoTelegram";
import GoSlon from "./GoSlon";
import OPList from "./OPList";
import OPPremList from "./OPPremList";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {Divider} from "@material-ui/core";
import $ from "jquery";
import TelegramIcon from '@material-ui/icons/Telegram';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(0),
    color: '#111111',
    fontSize: '13pt',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  desc: {
    marginLeft: theme.spacing(0),
    color: '#111111',
    fontSize: '12pt',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  icon_money: {
    marginLeft: 'auto',
  },
  menu: {
    backgroundColor: '#ffffff',
  },
  resoursePanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#fdfdfd',
    border: '1px solid #B7B7B7',
    borderRadius: 30,
  },
  topPanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  topIcon: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.1),
    marginBottom: theme.spacing(0.1),
  },
  infoPanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
  },
  btn_play: {
    background: 'linear-gradient(90deg, rgba(71,174,0,1) 0%, rgba(0,203,16,1) 57%, rgba(0,233,11,1) 100%)',
    borderRadius: 5,
    border: 0,
    color: 'white',
    width: '270px',
    height: '50px',
    fontSize: '14pt',
    boxShadow: '0 3px 5px 2px rgba(230, 255, 232, 1)',
  },
  btn_auth: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    borderRadius: 5,
    border: 0,
    color: 'white',
    width: '290px',
    height: '50px',
    fontSize: '11pt',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
  iconCup: {
    position: 'absolute',
    top: '21px',
    left: '10px',
  },
  iconGift: {
    position: 'absolute',
    top: '21px',
    right: '10px',
  }
}));



let costylCarrots = false;
let stavkaValue = 1; // СТАВКА от 1 до 5
let seconsFinishTurnir = 0; // ВРЕМЯ ДО КОНЦА ТУРНИРА
let canADS = true; // разрешено ли загружать рекламу
let loadedADS = false; // загружена реклама или нет

let count_clicks_screen = 0; // клики



// Подключаемся к Mini-APP Телеграм
let telegramAppChatLabs = window.Telegram.WebApp; //нужно получить объект window.Telegram.WebApp Телеграмма
telegramAppChatLabs.expand(); //разворачиваем на все окно
//telegramAppChatLabs.bg_color = '#ffffff';

let startParam = telegramAppChatLabs.initDataUnsafe.start_param; // параметр переданный в startapp=

//window.print(telegramAppChatLabs.initDataUnsafe.user.id);



// ОБЯЗ ПОДПИСКИ
const countActiveBtnsOP = 9; // сколько активно кнопок ОП 
const countActiveBtnsOPPrem = 1; // сколько активно ОП для премиум 

let countCompleteOP = 0; // сколько выполнил юзер
let countCompleteOPPrem = 0; // сколько выполнил прем юзер

let userIsPremium = false; // данный юзер с Премиум ТГ или нет


let haveOP = false; // true - все ОП выполнены
let have_subs_prem = false; // премиум ТГ у юзера


// запрет на свайп мини аппа
window.Telegram.WebApp.disableVerticalSwipes();

// включаем окно подверждения закрытия мини апп
window.Telegram.WebApp.enableClosingConfirmation();



window.Telegram.WebApp.onEvent('viewportChanged', function(data){
    
    if (data.isStateStable) {
       //window.Telegram.WebApp.showAlert('Привет! Давай измерим мощность твоего телефона');
    }

    // Публикация итории Видео/Фото
    /*
    let paramStory = {    
      text: "Привет @BunnyFPSBot"
    };
    window.Telegram.WebApp.shareToStory('https://fps1.ru/video_test.mp4', paramStory);
    */

});



// показываем текст в всплывающем окне ТГ
//window.Telegram.WebApp.showAlert('Привет! Давай измерим мощность твоего телефона');


const MenuGame = () => {

  const classes = useStyles();
  const srcIframe = 'https://fps1.ru/bunny_fps/game/';

  const maxWidthWindow = 600; //максимальная ширина iFrame
  const heightWindow = String(document.documentElement.clientHeight);
  let widthWindow = String(document.documentElement.clientWidth);
  if (widthWindow > maxWidthWindow) widthWindow = maxWidthWindow;


  const [openIframe, setOpenIframe] = useState(false); // Открыт-ли IFRAME с игрой
  const [authTG, setAuthTG] = useState(false); // ПРОВЕРКА АВТОРИЗАЦИИ
  const [authTG2, setAuthTG2] = useState(false); // ПРОВЕРКА АВТОРИЗАЦИИ 2

  const flashVars = getFlashVars();


  let hashUser = flashVars['hash']; //хэш авторизации Телеграм
  let chatIDUser = flashVars['id']; //айди юзера Телеграм


  const [myRecord, setMyRecord] = useState(0); // рекорд в обычном режиме - максимальный
  const [myScore, setMyScore] = useState(0); // счет в обычном режиме

  const [mySopernikScore, setSopernikScore] = useState(0); // счет соперника
  const [myStavka, setMyStavka] = useState(stavkaValue); // СТАВКА - для вывода в окне


  const [energy, setEnergy] = useState(0); // ЭНЕРГИЯ
  const [videoBonusCount, setVideoBonusCount] = useState(0); // КОЛ-ВО ПРОСМОТРОВ ВИДЕОРЕКЛАМЫ

  const [myCarrots, setMyCarrots] = useState(0); // МОРКОВКИ
  const [myDollars, setMyDollars] = useState(0); // ДОЛЛАРЫ

  const [codeComplete, setCodeComplete] = useState(0); // ПОЛУЧЕНА-ЛИ ЭНЕРГИЯ ПО КОДУ

  const [countOnline, setCountOnline] = useState(0); // Кол-во игроков онлайн

  const [showPreloader, setShowPreloader] = useState(false); // ПРЕЛОАДЕР

  const [showScreen, setShowScreen] = useState(false); // СКРИН ТГ


  const [showBank, setShowBank] = useState(false); // БАНК - ВЫВОД
  const [showInvite, setShowInvite] = useState(false); // ИНВАЙТ - ПРИГЛАШЕНИЕ



  const [showGame, setShowGame] = useState(false); // ИГРА ЗАПУЩЕНА - открыта
  const [startGame, setStartGame] = useState(false); // ИГРА ЗАГРУЖЕНА и НАЧАЛАСЬ

  const [searchSopernik, setSearchSopernik] = React.useState(false); // Окно: ПОИСК СОПЕРНИКА
  const [stopTurnir, setStopTurnir] = React.useState(false); // Окно: ТУРНИР ОСТАНОВЛЕН (пауза)
  const [noEnergy, setNoEnergy] = React.useState(false); // Окно: НЕТ ЭНЕРГИИ
  const [resultTurnir, setResultTurnir] = React.useState(false); // Окно: РЕЗУЛЬТАТ ТУРНИРА (победа/проигрыш)
  const [victoryTurnir, setVictoryTurnir] = React.useState(false); // РЕЗУЛЬТАТ ТУРНИРА
  const [stavkaTurnir, setStavkaTurnir] = React.useState(false); // Окно: СТАВКА НА ТУРНИР
  const [modeGame, setModeGame] = React.useState(false); // Окно: ВЫБОР РЕЖИМА ИГРА-ТУРНИР

  const [stopBot, setStopBot] = React.useState(false); // Окно: ДОСТУП К БОТУ ЗАКРЫТ


  const [opList, setOPList] = React.useState(false); // Окно: ОП для юзеров
  const [opPremList, setOPPremList] = React.useState(false); // Окно: ОП для PREMIUM

  const [haveAllSubsOP, setHaveAllSubsOP] = React.useState(0); // какая страница ОП выполнена

  

  const [btnOP1, setBtnOP1] = React.useState(false); // кнопка обяз подписки 1
  const [btnOP2, setBtnOP2] = React.useState(false); // кнопка обяз подписки 2
  const [btnOP3, setBtnOP3] = React.useState(false); // кнопка обяз подписки 3
  const [btnOP4, setBtnOP4] = React.useState(false); // кнопка обяз подписки 4
  const [btnOP5, setBtnOP5] = React.useState(false); // кнопка обяз подписки 5
  const [btnOP6, setBtnOP6] = React.useState(false); // кнопка обяз подписки 6
  const [btnOP7, setBtnOP7] = React.useState(false); // кнопка обяз подписки 7
  const [btnOP8, setBtnOP8] = React.useState(false); // кнопка обяз подписки 8
  const [btnOP9, setBtnOP9] = React.useState(false); // кнопка обяз подписки 9
  const [btnOP10, setBtnOP10] = React.useState(false); // кнопка обяз подписки 10


  const [selectPageOP, setSelectPageOP] = React.useState(1); // страинца обычных ОП
  const [selectPageOPPrem, setSelectPageOPPrem] = React.useState(1); // страница Премиум ОП


  const [goTelegram, setGoTelegram] = React.useState(false); // Окно: ПЕРЕХОД В ТЕЛЕГРАМ
  const [goIphone13, setGoIphone13] = React.useState(false); // Окно: ПЕРЕХОД В ПАРТНЕРКУ

  const [addBonus, setAddBonus] = React.useState(false); // Окно: БОНУС ЗА ПРОСМОТР РЕКЛАМЫ

  const [avatarPlayer, setAvatarPlayer] = React.useState(''); // Аватар игрока
  const [avatarSpoernik, setAvatarSpoernik] = React.useState(''); // Аватар соперника


  const [dataOPList, setDataOPList] = useState([]); // список актульных ссылок ОП
  const [dataOPPremList, setDataOPPremList] = useState([]); // список актуальных ссылок ОП Премиум


  const [TurnirMode, setTurnirMode] = React.useState(false); // РЕЖИМ ТУРНИРА - true - включен
  const [historyTurnir, setHistoryTurnir] = React.useState(false); // ИСТОРИЯ ТУРНИРА - true - включен
  const [tabsValue, setTabsValue] = React.useState(0); // номер переключателя топа

  const [timeTurnir, setTimeTurnir] = React.useState(0); // ДЛИТЕЛЬНОСТЬ ТУРНИРА С СОПЕРНИКОМ (секунды)
  const [timeFinishTurnir, setTimeFinishTurnir] = React.useState('24 ч. 0 м.'); // ВРЕМЯ ДО КОНЦА ТУРНИРА

  // Ловим МОМЕНТ ПЕРВОГО ЗАПУСКА
  React.useMemo(
      () => onStartApp(), // загружаем все данные
      []
  );

  // Ловим МОМЕНТ ИГРЫ
  React.useEffect(() => {

    // ТАЙМЕР ИГРЫ - обратный отсчет
    if (TurnirMode && startGame) {
      if (timeTurnir > 0) {
        setTimeout(() => setTimeTurnir(timeTurnir - 1), 1000); // отнимаем по 1 сек
      } else {

        // ИГРА ЗАВЕРШЕНА - таймер остановлен
        setTimeTurnir(0);
        goMenuTurnir(); // возвращаемся в меню
      }
    }

  });

  // ПЕРВЫЙ ЗАПУСК
  function onStartApp() {

    // сбрасываем айди и хэш
    chatIDUser = '';
    hashUser = '';

    // если авторизован
    if (hashUser !== undefined && hashUser !== '' && chatIDUser !== undefined && chatIDUser !== '') {
      setAuthTG(true);
      setAuthTG2(true);
      setModeGame(true); // ОКНО ВЫБОРА РЕЖИМА: ТЕСТ FPS или ТУРНИР
      getCountOnline(); // Подгружаем кол-во онлайн
    } else {
      
      // если запущен MINI-APPS
      if (startParam) {
        
        // разрешаем проверку
        setAuthTG(true);

      } else {

        // говорим что нужно авторизоваться
        setGoTelegram(true);


        // СПИСОК ОБЯЗАТЕЛЬНОЙ ПОДПИСКИ - для работы бота
        //setOPList(true);

        //setOPPremList(true);

      }
      
    }

  }


  // НАЧАТЬ ТУРНИР
  const onStartTurnir = () => {
    
    // если турнир еще не завершен
    if (seconsFinishTurnir > 0) {
        
        // если не хватает энергии
        if (energy <= 0) {
          setNoEnergy(true); // энергии нет
        } else {
          setStavkaTurnir(true); // Показываем окно ставок
        }
        
    }else{
        setStopTurnir(true); // турнир остановлен
    }
    
  }

  // ПОИСК СОПЕРНИКА - РЕКЛАМА ПЕРЕД
  const onSearchSopernik = (stavka) => {

    console.log('СТАВКА = ', stavka);
    stavkaValue = stavka; // задаем ставку турнира
    setMyStavka(stavkaValue);

    onAddSearchSopernik(); // ПОИСК СОПЕРНИКА

  }

  // ПОИСК СОПЕРНИКА - ЗАПУСК
  const onAddSearchSopernik = () => {

    hideStavkaTurnir(false);
    setShowPreloader(true);

    setMyScore(0); // Обнуляем счет
    costylCarrots = false; //костыль
    getAvaSopernik(hashUser); // получаем аватарку соперника онлайн

  }


  // СТАРТ ИГРЫ: Обычный режим
  const onGamePlay = () => {
    
    setShowGame(true);

    if(!openIframe) {

      setOpenIframe(true); // проверка мощности открыта
      
      //setTimeout(_endTimeOutIphone, 1500);
      function _endTimeOutIphone () {
          if (!TurnirMode) setGoIphone13(true);
      }

    }


    // Запрещаем тест, если нет подписок на ОП 
    if (!haveOP) {

      // показываем ОП для премиум
      if (have_subs_prem) {
        setOPPremList(true);
      }else{
        // показываем ОП для юзеров
        setOPList(true);
      }

    }

  };

  // СТАРТ ИГРЫ: Турнир
  const onGamePlayTurnir = () => {
    onGamePlay();
    setSearchSopernik(false);
    setTimeTurnir(randomInteger(10, 20)); // ТАЙМЕР ВРЕМЯ ТУРНИРА
  };

  
  // АЙФОН 13 - Партнерка
  const goIphone13Win = () => {
    //setGoIphone13(true);
  }

  // БАНК - ВЫВОД
  const goBank = () => {
    setShowBank(true);
  }

  // ИНВАЙТ - ПРИГЛАШЕНИЕ
  const goInvite = () => {
    setShowInvite(true);
  }

  
  // ОСТАНОВКА ТЕСТИРОВАНИЯ
  const stopTest = () => {
    
  }


  // Переход в меню ИГРЫ
  const goMenuGame = () => {
    setShowGame(false);
    setStartGame(false);
  }

  // Переход в меню ТУРНИРА
  const goMenuTurnir = () => {

    onUpdateTimeFinish(); // обновляем время до конца турнира

    setShowGame(false);
    setStartGame(false);

    //прибавляем 1 морковку - для теста
    if (!costylCarrots) {
      costylCarrots = true;

      setShowPreloader(true); //прелоадер
      setTurnir(hashUser); // записываем результат турнира в БД
    }

  }


  // Переход по ссылке в БОТ
  const onAuthTG = () => {
    //window.location.href = "tg://resolve?domain=BunnyFPSBot";
    window.location.href = "tg://resolve?domain=BunnyFPSBot&start=official_site"; // с записью новых по реферальной
  }

  window.addEventListener("message", onMessageReceived, false);
  function onMessageReceived(event) {
    
    const data = JSON.parse(event.data);
    
    // обновление рекорда
    if(data.message.action === "update_score") {
      
      const newScore = Number(data.message.value);
      setMyScore(newScore);

    }

    // тестирование завершено
    if(data.message.action === "over_testing") {
      
      const newScore = Number(data.message.value);
      setMyScore(newScore);

      // сохраняем рекорд
      saveMyRecord(newScore, startParam, authTG);

      //if (!TurnirMode) setGoIphone13(true);

    }

  }


  window.addEventListener("click", function(event) {

      // после 15 кликов открываем
    if (count_clicks_screen === 30 && !startParam) {
        
        count_clicks_screen = count_clicks_screen + 1;
        setAuthTG(true);
        setGoTelegram(false);
        setShowScreen(true);

        console.log('stop click');

    } else {
      count_clicks_screen = count_clicks_screen + 1;
      //console.log('click +1');
    }

  });



  function setStatSponsorOP(name_sponsor, user_id) {

    $.ajax({
      url: 'https://fps1.ru/bunny_fps/game/php/setSponsorOP.php',
      type: 'post',
      data: {
        chat_id: user_id,
        sponsor: name_sponsor
      },
      success: function (response) {
        console.log(response);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });

  }



  // Авторизация и синхронизация с БД
  function onAutorization() {
      
      onAutorization2();

      // если нет данных об авторизации, то не грузим ничего
      if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;

      // Авторизуемся - сохраняем данные в БД
      $.ajax({
          url: 'https://fps1.ru/bunny_fps/game/php/autorizationTG.php',
          type: 'post',
          data: {
            id: flashVars['id'],
            first_name: decodeURI(flashVars['first_name']),
            last_name: decodeURI(flashVars['last_name']),
            username: flashVars['username'],
            photo_url: decodeURIComponent(flashVars['photo_url']),
            auth_date: flashVars['auth_date'],
            hash: flashVars['hash']
          },
          success: function (response) {
              console.log('AUTH response = ', response);
              if (response === 'success') onGetUserData(); // Получаем данные юзера из БД
          },
          error: function(jqXHR, textStatus, errorThrown) {
              console.log(textStatus, errorThrown);
          }
      });

  }

  // НОВАЯ Авторизация и синхронизация с БД
  function onAutorization2() {

      // если нет данных об авторизации, то не грузим ничего
      if (!startParam) return;

      // Авторизуемся - сохраняем данные в БД
      $.ajax({
          url: 'https://fps1.ru/bunny_fps/game/php/autorizationTG2.php',
          type: 'post',
          data: {
            id: Number(startParam)
          },
          success: function (response) {
              console.log('AUTH response = ', response);
              if (response === 'success') onGetUserData(); // Получаем данные юзера из БД
          },
          error: function(jqXHR, textStatus, errorThrown) {
              console.log(textStatus, errorThrown);
          }
      });

  }


  // Получение данных юзера
  function onGetUserData() {
      $.ajax({
          url: 'https://fps1.ru/bunny_fps/game/php/getUserData.php',
          type: 'post',
          data: {
              chat_id: Number(startParam)
          },
          success: function (response) {

              const dataJson = JSON.parse(response);

              setMyRecord(Number(dataJson[0]['score']));


              // задаем актуальный список ссылок ОП
              setDataOPList(dataJson[0]['op_list']);

              // задаем актуальный список ссылок ОП Премиум
              setDataOPPremList(dataJson[0]['op_premium_list']);


              // если не подписан на спонсоров
              if (Number(dataJson[0]['check_sub']) == 0) {

                haveOP = false; // ОП не выполнен

              } else {

                haveOP = true; // ОП выполнен

              }

              // если есть ПРЕМИУМ ТГ
              if (Number(dataJson[0]['have_subs_prem']) == 1) {

                have_subs_prem = true; // премиум ТГ у юзера

              } else {

                have_subs_prem = false; // премиум ТГ у юзера

              }
              
              /*
              
              setAvatarPlayer(dataJson[0]['photo_url']);

              setEnergy(Number(dataJson[0]['energy']));
              setVideoBonusCount(Number(dataJson[0]['video_bonus']));

              setMyCarrots(Number(dataJson[0]['carrots']));
              setMyDollars(Number(dataJson[0]['dollars']).toFixed(3));

              setCodeComplete(Number(dataJson[0]['code_complete']));
              console.log('code_complete = ', Number(dataJson[0]['code_complete']));
              */

              // ЕСЛИ НЕТ ПОДПИСКИ НА ОБЯЗ КАНАЛЫ
              /*
              if (Number(startParam) === 0) {
                setStopBot(true);
              }
              */

              //seconsFinishTurnir = Number(dataJson[0]['time_finish']);
              //console.log('seconsFinishTurnir = ', seconsFinishTurnir);

              //onUpdateTimeFinish(); // обновляем время до конца турнира

          },
          error: function(jqXHR, textStatus, errorThrown) {
              console.log(textStatus, errorThrown);
          }
      });
  }

  function setTurnir(hash) {
    if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;

    console.log('SET TURNIR()');
    console.log('СТАВКА = ', stavkaValue);

    $.ajax({
      url: 'https://fps1.ru/bunny_fps/game/php/setTurnir.php',
      type: 'post',
      data: {
        hash: hash,
        stavka: stavkaValue
      },
      success: function (response) {

        setShowPreloader(false); //прелоадер

        if (response !== "stop") {

          //ПОБЕДА
          if (Number(response) === 1) {

            setSopernikScore(randomInteger(Math.round(myScore/1.2), myScore - 1) - 1);

            setMyCarrots(myCarrots + stavkaValue); // прибавляем морковки
            setMyDollars(Number(Number(myDollars) + 0.001).toFixed(3)); // прибавляем доллары
            setEnergy(energy - 1); // отнимаем энергию

            setVictoryTurnir(true); // победа в турнире
          }

          //Проигрыш
          if (Number(response) === 0) {

            if (stavkaValue > 1) setMyCarrots(myCarrots - stavkaValue); // отнимаем морковки
            setSopernikScore(randomInteger(myScore + 1, Math.round(myScore*1.2)) + 1);
            setEnergy(energy - 1); // отнимаем энергию

            setVictoryTurnir(false); // проигрыш в турнире
          }

          // Отображаем результат турнира
          setResultTurnir(true);

        } else {

          setStopTurnir(true); // турнир остановлен

        }

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });
  }

  function getAvaSopernik(hash) {
    if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;
    $.ajax({
      url: 'https://fps1.ru/bunny_fps/game/php/getAvatarOnline.php',
      type: 'post',
      data: {
        hash: hash
      },
      success: function (response) {

        console.log('response = ', response);

        setShowPreloader(false);

        // если турнир остановлен
        if (response === "stop") {

          setStopTurnir(true); // остановлен

        } else{

          // если турнир запущен
          if (response !== "0") {

            setSearchSopernik(true); // поиск соперника
            setAvatarSpoernik(response); // аватарка соперника
            setNoEnergy(false); // энергия есть
            setStopTurnir(false); // запущен

          } else {

            // если нет энергии
            setEnergy(0);
            setNoEnergy(true); // энергии нет
            setSearchSopernik(false);
          }

        }

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });
  }

  useEffect(() => {

    if (myScore > myRecord && (hashUser !== undefined || hashUser !== '')) {
      setMyRecord(myScore);
    }

  }, [myScore]);

  useMemo(
      () => onAutorization(),
      []
  );


  const handleChange = (event, newValue) => {

    setTabsValue(newValue);

    if (newValue === 0) setTurnirMode(false); // переключаемся в обычный режим
    if (newValue === 1) setTurnirMode(true); // переключаемся на турнир

    if (newValue === 2 && TurnirMode) setHistoryTurnir(true); // показываем историю турнира
    else setHistoryTurnir(false);

    getCountOnline(); // подгружаем кол-во онлайн

    setResultTurnir(false);
    setStartGame(false);
  };



  function getCountOnline() {

    if (hashUser === undefined || hashUser === '') return;
    $.ajax({
      url: 'https://fps1.ru/bunny_fps/game/php/getCountOnline.php',
      type: 'post',
      data: {
        //score: score,
      },
      success: function (response) {

        console.log('response = ', response);
        setCountOnline(Number(response));

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });

  }

  // отображение времени до конца турнира
  function onUpdateTimeFinish() {

    console.log('seconsFinishTurnir = ', seconsFinishTurnir);
    const hours = Math.floor(seconsFinishTurnir / 60 / 60);
    const minutes = Math.floor(seconsFinishTurnir / 60) - (hours * 60);
    //const seconds = timeStartTurnir % 60;

    setTimeFinishTurnir(hours + ' ч. ' + minutes + ' м.');

  }


  const onLoadComplete = () => {
    setStartGame(true);
    console.log('complete fun');
  };

  const hideSearchSopernik = () => {
    setSearchSopernik(false);
  };

  const hideNoEnergy = () => {
    setNoEnergy(false);
    setAddBonus(false);
  };

  const addEnergy = () => {
    setEnergy(energy + 30); // прибавляем 30 энергии
    setCodeComplete(1); // код активирован
    setStopTurnir(); // снимаем турнир с паузы
  };


  // Бонус за просмотр видеорекламы
  const onVideoBonus = () => {

  };

  // Открываем окно получения энергии
  const onViewEnergyCode = () => {

    setNoEnergy(true); // энергии нет

  };

  // Начисляем бонус за просмотр видео рекламы
  const onAddVideoBonus = () => {

    setAddBonus(true);
    setEnergy(energy + 1); // прибавляем 1 энергии
    if (stopTurnir) setStopTurnir(); // снимаем турнир с паузы
    setVideoBonusCount(videoBonusCount + 1); // +1 просмотр к рекламе

  };

  
  const onCloseGift = () => {
    setGoIphone13(false);
  };

  const hideResultTurnir = () => {
    setResultTurnir(false);
    viewADS(false); // показываем рекламу
  };

  const hideStavkaTurnir = () => {
    setStavkaTurnir(false);
  };

  const changeModeGame = (turnirMode) => {
    if (turnirMode === 1) {
      setTabsValue(1);
      setTurnirMode(true);
    }
    setModeGame(false);

    setShowPreloader(true);

    // для админов отключачем рекламу
    //if (chatIDUser === '193523422' || chatIDUser === '437127745') canADS = false; // запрещаем загрузку рекламы

    // УБИРАЕМ ВРЕМЕННО ЯНДЕКС РЕКЛАМУ
    canADS = false; // запрещаем загрузку рекламы

    // загружаем рекламу
    viewADS(false);

    /*
    // если через 8 сек если не загружена реклама, то и не показываем больше
    setTimeout(_endTimeOut, 11000);
    function _endTimeOut () {

      if (!loadedADS) {
        canADS = false; // запрещаем загрузку рекламы
        setShowPreloader(false);
      }

    }
    */

  };

  // Функция вызова показа рекламы
  const viewADS = (video) => {
    if (!canADS) {
        setShowPreloader(false);
    } else {
        if (video) window.addYaAd(true, completeVideoAds, preloaderAds); // показываем  видеорекламу
        else window.addYaAd(false, completeAds, preloaderAds); // показываем обычную рекламу
    }
  }

  // реклама загружена
  const completeAds = () => {
    console.log('РЕКЛАМА ПОДГРУЖЕНА!');
    loadedADS = true;
    setShowPreloader(false);

    // если запускается поиск соперника
    /*
    if (stavkaTurnir) {
      onAddSearchSopernik(); // ПОИСК СОПЕРНИКА
    }
    */
  }

  // ВИДЕОРЕКЛАМА УСПЕШНО ПОКАЗАНА
  const completeVideoAds = () => {
    loadedADS = true;
    setShowPreloader(false);

    onAddVideoBonus(); // выдаем бонус
  }


  // реклама загружается - ожидаем
  const preloaderAds = () => {
    console.log('Реклама загружается');
    setShowPreloader(true);
  }

  // кнопка показов - была ниже на 765 строке
  /*
        { showGame && !TurnirMode ?
          <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/gift_icon.png" onClick={() => { goIphone13Win(); }} className={classes.iconGift}/>
      : null }
  */



  // КЛИК ПО СПОНСОРУ ИЗ СПИСКА 
  const goClickOP = (num_btn) => {

    let openLink = true; // false - запрещаем повторно открыть ссылку

    const urls_sub = dataOPList;
    
    console.log('urls ', urls_sub[num_btn-1]);
    console.log('num_btn = ', num_btn);

    console.log('urls_sub[num_btn-1].length = ', urls_sub[num_btn-1].length);


    let url_sub = ''; // текущая ссылка на перехода на спонсора

    // если на 1 кнопку несколько ссылок (показы чередованием в равном кол-ве)
    if (Array.isArray(urls_sub[num_btn-1])) {
      
      // выбираем случайную ссылку из всех
      const randomIndex = Math.floor(Math.random() * (urls_sub[num_btn-1].length));

      console.log('randomIndex = ', randomIndex);

      url_sub = urls_sub[num_btn-1][randomIndex]; // задаем ссылку 

    } else {

      // если на 1 кнопку 1 ссылка
      url_sub = urls_sub[num_btn-1]; // задаем ссылку 

    }

    console.log('url_sub = ', url_sub);


    if (num_btn == 1) {
      
      // Переход по ссылке на спонсора
      if (!btnOP1) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut1, 6000);
      function endTimeOut1() {
          if (!btnOP1) setBtnOP1(true); // подписка выполнена
      }

    }


    if (num_btn == 2) {
      
      // Переход по ссылке на спонсора
      if (!btnOP2) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut2, 6000);
      function endTimeOut2() {
          if (!btnOP2) setBtnOP2(true); // подписка выполнена
      }

    }

    if (num_btn == 3) {
      
      // Переход по ссылке на спонсора
      if (!btnOP3) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut3, 6000);
      function endTimeOut3() {
          if (!btnOP3) setBtnOP3(true); // подписка выполнена
      }

    }

    if (num_btn == 4) {
      
      // Переход по ссылке на спонсора
      if (!btnOP4) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut4, 3000);
      function endTimeOut4() {
          if (!btnOP4) setBtnOP4(true); // подписка выполнена
      }

    }

    if (num_btn == 5) {
      
      // Переход по ссылке на спонсора
      if (!btnOP5) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut5, 3000);
      function endTimeOut5() {
          if (!btnOP5) setBtnOP5(true); // подписка выполнена
      }

    }

    if (num_btn == 6) {
      
      // Переход по ссылке на спонсора
      if (!btnOP6) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut6, 3000);
      function endTimeOut6() {
          if (!btnOP6) setBtnOP6(true); // подписка выполнена
      }

    }

    if (num_btn == 7) {
      
      // Переход по ссылке на спонсора
      if (!btnOP7) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut7, 3000);
      function endTimeOut7() {
          if (!btnOP7) setBtnOP7(true); // подписка выполнена
      }

    }

    if (num_btn == 8) {
      
      // Переход по ссылке на спонсора
      if (!btnOP8) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut8, 3000);
      function endTimeOut8() {
          if (!btnOP8) setBtnOP8(true); // подписка выполнена
      }

    }

    if (num_btn == 9) {
      
      // Переход по ссылке на спонсора
      if (!btnOP9) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut9, 3000);
      function endTimeOut9() {
          if (!btnOP9) setBtnOP9(true); // подписка выполнена  
      }

    }

    
    // *** ВЕДЕМ СТАТУ ВЫПОЛНЕНИЙ ***
    if (openLink) setStatSponsorOP(url_sub, startParam); // сохраняем в БД


    // *** ВЕДЕМ СТАТУ ВЫПОЛНЕНИЙ ***
    /*
    if (url_sub == 'https://t.me/+FY9fpoYsnsI2ZGVi') {
      setStatSponsorOP(url_sub, startParam); // сохраняем в БД
    }

    // @BotsReklam - notpixel
    if (url_sub == 'https://t.me/notpixel/app?startapp=f541000485_t_s631453' && openLink) {
      setStatSponsorOP('notpixel_16_10', startParam); // сохраняем в БД
    }

    
    // @BotsReklam - CoinFlipGame_bot
    if (url_sub == 'https://t.me/CoinFlipGame_bot/DOXCoinFlip?startapp=7300935974' && openLink) {
      setStatSponsorOP('CoinFlipGame_bot_13_10', startParam); // сохраняем в БД
    }

    // @BotsReklam - TINDER
    if (url_sub == 'https://t.me/TinderZnakomstva_bot?start=1010traf' && openLink) {
      setStatSponsorOP('BotsReklam_12_10', startParam); // сохраняем в БД
    }

    // ЛАДЕСОВ
    if (url_sub == 'https://t.me/major/start?startapp=2005387243-305094295' && openLink) {
      setStatSponsorOP('ladesov_major_09_10', startParam); // сохраняем в БД
    }

    // ЛАДЕСОВ 2
    if (url_sub == 'https://t.me/bums/app?startapp=ref_z4PxXUL1' && openLink) {
      setStatSponsorOP('ladesov_bums_16_10', startParam); // сохраняем в БД
    }

    // ЛАДЕСОВ 3
    if (url_sub == 'https://t.me/coub/app?startapp=coub__marker_17721114' && openLink) {
      setStatSponsorOP('ladesov_coub_19_10', startParam); // сохраняем в БД
    }

    // XAITBOEV - бот музыка
    if (url_sub == 'https://t.me/Fullsaverbot?start=Fps' && openLink) {
      setStatSponsorOP('Fullsaverbot_09_28', startParam); // сохраняем в БД
    }

    // XAITBOEV - бот скачать видео
    if (url_sub == 'https://t.me/RuSaverBot?start=fps' && openLink) {
      setStatSponsorOP('RuSaverBot_09_28', startParam); // сохраняем в БД
    }
    */
    // ***


  };



  // КЛИК ПО СПОНСОРУ ПРЕМИУМ
  const goClickOPPrem = (num_btn) => {

    let openLink = true; // false - запрещаем повторно открыть ссылку

    const urls_sub = dataOPPremList;
    
    console.log('urls ', urls_sub[num_btn-1]);
    console.log('num_btn = ', num_btn);

    console.log('urls_sub[num_btn-1].length = ', urls_sub[num_btn-1].length);


    let url_sub = ''; // текущая ссылка на перехода на спонсора

    // если на 1 кнопку несколько ссылок (показы чередованием в равном кол-ве)
    if (Array.isArray(urls_sub[num_btn-1])) {
      
      // выбираем случайную ссылку из всех
      const randomIndex = Math.floor(Math.random() * (urls_sub[num_btn-1].length));

      console.log('randomIndex = ', randomIndex);

      url_sub = urls_sub[num_btn-1][randomIndex]; // задаем ссылку 

    } else {

      // если на 1 кнопку 1 ссылка
      url_sub = urls_sub[num_btn-1]; // задаем ссылку 

    }

    console.log('url_sub = ', url_sub);


    if (num_btn == 1) {
      
      // Переход по ссылке на спонсора
      if (!btnOP1) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut1, 5000);
      function endTimeOut1() {
          if (!btnOP1) setBtnOP1(true); // подписка выполнена
      }

    }


    if (num_btn == 2) {
      
      // Переход по ссылке на спонсора
      if (!btnOP2) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut2, 5000);
      function endTimeOut2() {
          if (!btnOP2) setBtnOP2(true); // подписка выполнена
      }

    }

    if (num_btn == 3) {
      
      // Переход по ссылке на спонсора
      if (!btnOP3) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut3, 3000);
      function endTimeOut3() {
          if (!btnOP3) setBtnOP3(true); // подписка выполнена
      }

    }

    if (num_btn == 4) {
      
      // Переход по ссылке на спонсора
      if (!btnOP4) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut4, 3000);
      function endTimeOut4() {
          if (!btnOP4) setBtnOP4(true); // подписка выполнена
      }

    }

    if (num_btn == 5) {
      
      // Переход по ссылке на спонсора
      if (!btnOP5) window.Telegram.WebApp.openTelegramLink(url_sub);
      else openLink = false;

      // засекаем 3 сек
      setTimeout(endTimeOut5, 3000);
      function endTimeOut5() {
          if (!btnOP5) setBtnOP5(true); // подписка выполнена
      }

    }

    // *** ВЕДЕМ СТАТУ ВЫПОЛНЕНИЙ ***
    
    if (openLink) setStatSponsorOP(url_sub, startParam); // сохраняем в БД

    // ***

  };

  
  // Ловим обновление кнопок ОП
  useEffect(() => {

    if (opList) checkOP(); // проверяем все обычные ОП
    if (opPremList) checkOPPrem(); // проверяем все Премиум ОП

  }, [btnOP1, btnOP2, btnOP3, btnOP4, btnOP5, btnOP6, btnOP7, btnOP8, btnOP9]);


  // ПРОВЕРКА ОБЫЧНЫХ ПОДПИСОК
  const checkOP = () => {
    
    let countCompleteOP = 0; // сколько кнопок ОП выполнено

    // Проверка выполнения
    if (btnOP1) countCompleteOP = countCompleteOP + 1;
    if (btnOP2) countCompleteOP = countCompleteOP + 1;
    if (btnOP3) countCompleteOP = countCompleteOP + 1;
    if (btnOP4) countCompleteOP = countCompleteOP + 1;
    if (btnOP5) countCompleteOP = countCompleteOP + 1;
    if (btnOP6) countCompleteOP = countCompleteOP + 1;
    if (btnOP7) countCompleteOP = countCompleteOP + 1;
    if (btnOP8) countCompleteOP = countCompleteOP + 1;
    if (btnOP9) countCompleteOP = countCompleteOP + 1;


    // для статы сохраняем в рекорд
    saveMyRecord(countCompleteOP, startParam, authTG);

    console.log('countCompleteOP = ', countCompleteOP);

    // если первые 3 ОП выполнены
    if (btnOP1 && btnOP2 && btnOP3 && !btnOP4 && !btnOP5 && !btnOP6 && !btnOP7 && !btnOP8 && !btnOP9) {

      // показываем кнопку "Далее"
      setHaveAllSubsOP(1);

    }

    // если вторые 3 ОП выполнены
    if (btnOP1 && btnOP2 && btnOP3 && btnOP4 && btnOP5 && btnOP6 && !btnOP7 && !btnOP8 && !btnOP9) {

      // показываем кнопку "Далее"
      setHaveAllSubsOP(2);

    }

    // если третьи 3 ОП выполнены
    if (btnOP1 && btnOP2 && btnOP3 && btnOP4 && btnOP5 && btnOP6 && btnOP7 && btnOP8 && btnOP9) {

      // показываем кнопку "Продолжить"
      setHaveAllSubsOP(3);
      
      // сохраняем в стату как выполнено
      saveMyRecord(-1, startParam, authTG);

    }

  };

  // Нажали Продолжить в ОП
  const completeAllSubOP = (numPage) => {

    if (numPage == 1) setSelectPageOP(2); // переходим на страницу 2
    if (numPage == 2) setSelectPageOP(3); // переходим на страницу 3
    
    if (numPage == 3) {

      setOPList(false); // скрываем Окно ОП
      haveOP = true; // все ОП выполнены

    }

  };



  // ПРОВЕРКА ПРЕМИУМ ПОДПИСОК
  const checkOPPrem = () => {
    
    let countCompleteOP = 0; // сколько кнопок ОП выполнено

    // Проверка выполнения
    if (btnOP1) countCompleteOP = countCompleteOP + 1;
    if (btnOP2) countCompleteOP = countCompleteOP + 1;
    if (btnOP3) countCompleteOP = countCompleteOP + 1;
    if (btnOP4) countCompleteOP = countCompleteOP + 1;
    if (btnOP5) countCompleteOP = countCompleteOP + 1;
    if (btnOP6) countCompleteOP = countCompleteOP + 1;
    if (btnOP7) countCompleteOP = countCompleteOP + 1;
    if (btnOP8) countCompleteOP = countCompleteOP + 1;
    if (btnOP9) countCompleteOP = countCompleteOP + 1;


    // для статы сохраняем в рекорд
    saveMyRecord(countCompleteOP, startParam, authTG);

    console.log('countCompleteOP = ', countCompleteOP);

    // если первые 2 ОП выполнены
    if (btnOP1 && btnOP2 && !btnOP3 && !btnOP4) {

      // показываем кнопку "Далее"
      setHaveAllSubsOP(1);

    }

    // если вторые 3 ОП выполнены
    if (btnOP1 && btnOP2 && btnOP3 && btnOP4 && btnOP5) {

      // показываем кнопку "Продолжить"
      setHaveAllSubsOP(2);

      // сохраняем в стату как выполнено
      saveMyRecord(-1, startParam, authTG);

    }

  };


  // Нажали Продолжить в ОП ПРЕМИУМ
  const completeAllSubOPPrem = (numPage) => {

    if (numPage == 1) setSelectPageOPPrem(2); // переходим на страницу 2
    
    if (numPage == 2) {

      setOPPremList(false); // скрываем Окно ОП
      haveOP = true; // все ОП выполнены

    }

  };




  return (

    <Container maxWidth="sm" disableGutters>


    { showScreen ?
          <Avatar variant={"rounded"} alt="The image" src={"https://fps1.ru/screen_tg_bot2.jpg"} style={{
      width: "100%",
      height: "20%",
    }} />
      : null }



      { showGame && !setShowScreen && !TurnirMode ?
          <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/btnBack3.png" onClick={() => { goMenuGame(); }} className={classes.iconCup}/>
      : null }



      <Box className={classes.topPanel}>

        { !TurnirMode
            ? <div className={classes.topIcon}>
              <Typography className={classes.title}>
                {myRecord}K
              </Typography>
              <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/bunny3.png" onClick={() => { stopTest(); }}/>
            </div>
            : null
        }

        { !showGame && TurnirMode
            ? <div className={classes.topIcon} style={{ marginRight: '0.4rem' }}>
              <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/invite.png" onClick={() => { goInvite(); }}/>
            </div>
            : null
        }


        {!showGame && TurnirMode
            ? <div className={classes.resoursePanel}>

              <div className={classes.topIcon} style={{ marginLeft: widthWindow < 330 ? '0.2rem' : '0.9rem' }}>
                <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/energy3.png"/>
                <Typography className={classes.title}>
                  {energy}
                </Typography>
              </div>

              <div className={classes.topIcon} style={{ marginLeft: '0.6rem' }}>
                <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/carrot2.png"/>
                <Typography className={classes.title}>
                  {myCarrots}
                </Typography>
              </div>

              <div className={classes.topIcon} style={{ marginLeft: '0.6rem', marginRight: widthWindow < 330 ? '0.2rem' : '0.9rem' }}>
                <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/dollar.png"/>
                <Typography className={classes.title}>
                  {myDollars}$
                </Typography>
              </div>

            </div>
            : null
        }


        { !showGame && TurnirMode
            ? <div className={classes.topIcon} style={{ marginLeft: '0.4rem' }}>
              <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/bank4.png" onClick={() => { goBank(); }}/>
            </div>
            : null
        }

          { showGame && TurnirMode
              ? <div className={classes.topIcon}>
                  <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/bunny3.png" />
                  <Typography className={classes.title}>
                      { myScore }К
                  </Typography>
              </div>
              : null
          }


          { showGame && TurnirMode
              ? <div className={classes.topIcon} style={{ marginLeft: '1.1rem' }}>
                  <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/time.png" />
                  <Typography className={classes.title}>
                      { timeTurnir }
                  </Typography>
              </div>
              : null
          }

      </Box>

      <Divider variant="fullWidth" />

      { openIframe ? <Iframe show={showGame} source={srcIframe} completeLoad={onLoadComplete} width={widthWindow} height={heightWindow-60}/> : null }

      { !showGame ?
          <Box>
            <Paper className={classes.root}>
              <Tabs
                  value={ tabsValue }
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
              >
                <Tab label="Обычный" />
                <Tab label="Онлайн-турнир" disabled={!authTG2} />
                { TurnirMode && authTG && <Tab icon={<HistoryIcon/>}/> }
              </Tabs>
            </Paper>

            { historyTurnir && <Typography className={classes.desc}> История предыдущего турнира </Typography> }

            <Paper elevation={3}>
              {
                (TurnirMode && !historyTurnir)
                  && <TurnirTopList history={false} chat_id={chatIDUser} height={ authTG ? (heightWindow - 265) : (heightWindow - 365) }/>
              }
              { !TurnirMode && <TopList height={ authTG ? (heightWindow - 255) : (heightWindow - 235) }/> }
              { historyTurnir && <TurnirTopList history={true} chat_id={chatIDUser} height={ heightWindow - 315 }/>}
            </Paper>

            { !historyTurnir && authTG && !TurnirMode &&
            <Typography className={classes.title}>
              {"Слабо попасть в топ?!"}
            </Typography>
            }


            { !historyTurnir && authTG && TurnirMode ?
              <div className={classes.infoPanel}>
                <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/user_online2.png" style={{ height: '32px', width: '32px' }} />
                <Typography className={classes.title}>
                  { countOnline.toString() }
                </Typography>

                <Avatar src="https://fps1.ru/bunny_fps/menu/imgs/time_finish2.png" style={{ marginLeft: '1.1rem', height: '30px', width: '30px' }} />
                <Typography className={classes.title}>
                  { timeFinishTurnir }
                </Typography>
              </div> : null
            }


            { historyTurnir && <Typography className={classes.desc} style={{ padding: '0 30px' }}> Суммарный заработок игроков за вчерашний турнир более 45$ </Typography> }


            { authTG &&
            <Button onClick={() => {
              TurnirMode ? onStartTurnir() : onGamePlay()
            }} className={classes.btn_play} size="large" variant="contained" color="primary">
              { TurnirMode ? "НАЧАТЬ ТУРНИР" : "ЗАПУСТИТЬ" }
            </Button>
            }

            <Typography className={classes.desc}>
              { authTG ? "" : "Авторизуйся через Телеграм, чтобы проверить мощность и сохранить рекорд!" }
            </Typography>


            { /*!authTG ?
                <Button onClick={() => { onAuthTG() }} className={classes.btn_auth} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary">
                  Войти через Телеграм
                </Button>
                : null
              */
            }


          </Box>
      : null }

      { searchSopernik && !stopTurnir ? <StartTurnir open={true} goGamePlay={onGamePlayTurnir} ava1={avatarPlayer} ava2={avatarSpoernik}/> : null }
      { stopTurnir && !noEnergy ? <StopTurnir open={true} onClose={hideSearchSopernik}/> : null }
      { noEnergy ? <NoEnergy open={true} onClose={hideNoEnergy} energy={energy} countEnergy={30} onPlusEnergy={addEnergy} hash={hashUser} chat_id={chatIDUser} activated={false}/> : null }
      { addBonus ? <NoEnergy open={true} onClose={hideNoEnergy} energy={energy} countEnergy={1} onPlusEnergy={hideNoEnergy} hash={hashUser} chat_id={chatIDUser} activated={true}/> : null }

      { resultTurnir && <ResultTurnir open={true} onClose={hideResultTurnir} victory={victoryTurnir} stavka={myStavka} score1={myScore} score2={mySopernikScore} ava1={avatarPlayer} ava2={avatarSpoernik}/> }

      <Stavka open={stavkaTurnir} onClose={hideStavkaTurnir} onSearch={onSearchSopernik} codeComplete={codeComplete} onBonus={onVideoBonus} onBonus30={onViewEnergyCode} videoBonusCount={videoBonusCount} myCarrots={myCarrots} hash={hashUser} chat_id={chatIDUser}/>
      <ModeGame open={authTG && modeGame} onChange={changeModeGame}/>
      <Preloader open={showPreloader}/>

      <Bank open={showBank} dollars={Number(myDollars)} onClose={() => setShowBank(false)}/>
      <Invite open={showInvite} onClose={() => setShowInvite(false)}/>

      { stopBot ? <StopBot open={true} /> : null }

      { goTelegram ? <GoTelegram open={true} /> : null }

      { opList ? <OPList open={true} onClickOP={goClickOP} onGoStart={completeAllSubOP} selectPage={selectPageOP} have_subs={haveAllSubsOP} btn1={btnOP1} btn2={btnOP2} btn3={btnOP3} btn4={btnOP4} btn5={btnOP5} btn6={btnOP6} btn7={btnOP7} btn8={btnOP8} btn9={btnOP9} /> : null }
      { opPremList ? <OPPremList open={true} onClickOP={goClickOPPrem} onGoStart={completeAllSubOPPrem} selectPage={selectPageOPPrem} have_subs={haveAllSubsOP} btn1={btnOP1} btn2={btnOP2} btn3={btnOP3} btn4={btnOP4} btn5={btnOP5} /> : null }

      { goIphone13 ? <GoSlon open={true} onClose={onCloseGift} /> : null }

	</Container>

  );
}

export default MenuGame;
